import React, { createContext } from 'react';
import useMetadata from '../hooks/useMeta';
import useData from '../hooks/useData';
import useTagging from '../hooks/useTagging';
import {ShoppingPageContextType, CategoryInterface } from '../types/ShoppingPageContextTypes'
import {ElementInterface} from '../types/ShoppingPageMetaTypes'
import _ from 'lodash';
import { OfferType } from '../types/ShoppingPageDataTypes';

type Props = {
  children : React.ReactNode
}

const SPContext = createContext<ShoppingPageContextType>({} as ShoppingPageContextType);

function SPProvider({children}:Props){
const {
    meta,
    getMeta,
    fetchMetaApi,
    getMetaElements,
    metaRequestStatus
  } = useMetadata();

  const {
    getOffersDataByCategory,
    data,
    fetchDataApi,
    dataRequestStatus
  } = useData();


  const {
    updateTaggingData 
  } = useTagging();

  const getCategoryData = (category:string):CategoryInterface =>{
    let categoryData = {} as CategoryInterface;
    if(metaRequestStatus==='success' && dataRequestStatus==='success'){
      let meta = getMeta('SHOPPING_PAGE_WITH_CATEGORY');
      categoryData = {
        ...meta.filter((e)=>e.id===category)[0],
        offers: getOffersDataByCategory(category)
      }
    }
    return categoryData;    
  }
  const getCategories = (): ElementInterface[] => {
    let categories = [] as ElementInterface[];
    if (metaRequestStatus === 'success' && dataRequestStatus === 'success' && data.offers) {
      categories = data.offers.reduce(function(acc:ElementInterface[],curr:OfferType) {
        if(curr.topOffer && !_.find(acc, {id: 'topOffers'})){
          acc.push(getMetaElements('SHOPPING_PAGE_WITH_CATEGORY').topOffers)
        }
        let currCategory = curr.productCategory.split(",").map((o)=>_.camelCase(_.trim(o)));
        currCategory.forEach((c)=>{
          if(!_.find(acc, {id: c}) && getMetaElements('SHOPPING_PAGE_WITH_CATEGORY')[c]){
            acc.push(getMetaElements('SHOPPING_PAGE_WITH_CATEGORY')[c])
          }
        })
        return acc;
      },[] as ElementInterface[]);
      categories.push(getMetaElements('SHOPPING_PAGE_WITH_CATEGORY').allCards);
    }
    return categories;
  }

  return (
    <SPContext.Provider
      value={{
         meta,
         getMeta,
         fetchMetaApi,
         getMetaElements,
         metaRequestStatus,
         data,
         fetchDataApi,
         dataRequestStatus,
         getCategoryData,
         getCategories,
         updateTaggingData     
       }} 
    >
      {children}
    </SPContext.Provider>
  );
}

export { SPContext, SPProvider };