export const BASE_URL:string = "/olaf/service";
export const META_URL:string = `${BASE_URL}/channel/meta/shopping-page`;
export const DATA_URL:string = `${BASE_URL}/shopping-page/shoppingPage`;
export const SUMMARY_URL:string = `${BASE_URL}/summaryBox`;
export const UPLOAD_URL:string = `${BASE_URL}/citupload`;
export const CLEAR_URL:string = `${BASE_URL}/clear`;
export const UPDATE_RTM_URL:string = `${BASE_URL}/shopping-page/updateRtm`;
export const INIT_URL:string = `${BASE_URL}/init`;
export const PREPROCESS_URL:string = `${BASE_URL}/channel/preProcess`;


export const UIContext:string = 'olafui';

export const getChannelName = (urlPath:string):string => {
    var splitIndex = 1;
    splitIndex = urlPath?.includes('olafui') ? splitIndex + 1 : splitIndex;
    return urlPath.split("/")[splitIndex];
}
export const getJourneyType = (urlPath:string):string => {
    var splitIndex = 2;
    splitIndex = urlPath?.includes('olafui') ? splitIndex + 1 : splitIndex;
    return urlPath.split("/")[splitIndex];
}

export const getUIContext = (urlPath:string):string => {
    return urlPath?.includes('olafui') ? '/olafui/' : '/'
}
