import React,{useRef ,useState,useEffect, useCallback,useContext} from 'react';
import { Type,Button,Dialog,ModalRef,Loading } from '@barclays/blueprint-react';
import { ElementMapType } from '../types/ShoppingPageMetaTypes';
import axios from '../hooks/axiosAdaptor';
import { UPLOAD_URL } from '../../../constants'
import { SPContext } from '../context/SPContext';
import './clouditupload.css'

type Props = {
    meta: ElementMapType,
    productCode: String
}
export default function CloudItUpload({meta,productCode}:Props) {
    const modalRef = useRef<ModalRef>({} as ModalRef);
    const [cloudItAPISuccess,setCloudItAPISuccess] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);
    const {updateTaggingData } = useContext(SPContext);
    useEffect(() => {
      document.body.classList.toggle('modal-open', loading);
    },[loading]);
    const updateTaggingOnClick = useCallback((label:string,method:string) => {
      try{
        updateTaggingData({
          'event_label': label,
          'event_method': method,
          'event_type': 'click',
        });
      }
      catch(e){
        console.warn("Tagging called failed");
      }
    },[updateTaggingData]);
    const invokeCloudIt = () => {
      setLoading(true);
      updateTaggingOnClick('CloudIt','link');
      axios.post(UPLOAD_URL,{ 
        productCode: productCode,
        type : "PRE_SECCI" 
      })
      .then(function (response) {
        if(response.data && response.data.status==="SUCCESS"){
          setCloudItAPISuccess(true);
        }
        setLoading(false);
        doOpenModal();
      })
      .catch(function () {
        setLoading(false);
        doOpenModal();
      });
    }
    const doOpenModal = useCallback(() => {
        modalRef.current.open();
      }, []);
    const doCloseModal = useCallback(() => {
      modalRef.current.close();
      let eventLabel = cloudItAPISuccess?'UploadSucc:Close':'UploadFail:Close';
      updateTaggingOnClick(eventLabel,'button');
    }, [cloudItAPISuccess,updateTaggingOnClick]);
    return (<>
        <Type element='p' className='pcci txt' marginBottom='xs'>{meta.CloudItLinkText.placeholder}</Type>
        <Button small onClick={invokeCloudIt}>{meta.CloudItLinkText.label}</Button>
        {loading &&  <div className="loading-overlay"><div className="loading"><Loading shape="circle" /></div></div>}
        <Dialog
          ref={modalRef}
          alignment='default'
          size='content'
          transparency='dark'
          title={<Type element='h2' marginBottom size='lg' alignment='center'>{cloudItAPISuccess?meta.CloudItSuccess.placeholder:meta.CloudItError.placeholder}</Type>}
        >
          <Type marginBottom alignment='center'>{cloudItAPISuccess?meta.CloudItSuccess.label:meta.CloudItError.label}</Type>
          <Button fullWidth onClick={doCloseModal} variant='primary'>Close</Button>
        </Dialog>
        </>);
}