import { Outlet } from 'react-router-dom';
import './App.css';

// import { BarclaycardThemeProvider } from '@barclays/blueprint-react';
// import { BarclaysThemeProvider } from '@barclays/blueprint-react';
// import { BarclaysLegacyThemeProvider } from '@barclays/blueprint-react';

function App() {
  return (
      // <BarclaysLegacyThemeProvider>
        <Outlet />
      // </BarclaysLegacyThemeProvider>
  );
}

export default App;
