import {ShoppingPageMetaType} from '../types/ShoppingPageMetaTypes'

let _meta:ShoppingPageMetaType = {
  "screens": [
    {
      "screenid": "SHOPPING_PAGE",
      "elements": [
        {
          "id": "ApplyNowButton",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Apply Now",
          "listValues": [],
          "validations": []
        },
        {
          "id": "NPS_subtitle",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Whether you're new to credit, you want to manage your money better or you're looking for rewards, we've got a range of cards to help. Unsure which card is right for you? Use our filter to find your card...",
          "listValues": [],
          "validations": []
        },
        {
          "id": "NoOfferErrorTitle",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "We're Sorry",
          "listValues": [],
          "validations": []
        },
        {
          "id": "accountFeeText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Monthly fee",
          "listValues": [],
          "validations": []
        },
        {
          "id": "PreApprovedText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Being pre-approved means the interest rate, interest-free period and fee (if there is one), are all confirmed. The only thing we can't guarantee is the credit limit - this will be confirmed after you apply.",
          "tooltip": "You're guaranteed to get this card, provided you use the same details as you gave us, and you pass final lender's checks, including fraud and money laundering. COPY TBC",
          "listValues": [],
          "validations": [],
          "placeholder": "You are pre-approved* TBC"
        },
        {
          "id": "noofferbranch_text",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Sorry, we can't offer you a card right now",
          "listValues": [],
          "validations": []
        },
        {
          "id": "PA_subtitle",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "If you apply for a pre-approved card you will be accepted, subject to passing ID and fraud checks. This offer is only available to new Barclaycard customers.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "RepresentativeExampleText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Representative Example",
          "tooltip": "Most accepted customers get:",
          "listValues": [],
          "validations": []
        },
        {
          "id": "footerText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Please note it's not possible to apply for a new Barclaycard if you already have a credit card provided by us, including one of our <a class='overlay'>partner cards</a>.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "CloudItLinkText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Upload your important information to the App / Online Banking. Find it under 'Statements and Documents'",
          "listValues": [],
          "validations": []
        },
        {
          "id": "noofferbranch_colleague_title",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Colleague instructions",
          "listValues": [],
          "validations": []
        },
        {
          "id": "noofferbranch_colleague_introText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Please read the following statement to the customer.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "noteText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "The approval of your application depends on financial circumstances and borrowing history, so do the terms you may be offered. The balance transfer period and interest rates may differ from those shown.",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "ManagingBarclaycardText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "<div class='product-details-content'> <h3>Managing your Barclaycard</h3> <div> <b>Staying on top of your account</b>- <span>if you miss payments or go over your credit limit, you will incur additional costs through interest and fee charges; you will also lose any promotional interest rates. So please make sure you've read and understood the terms and conditions before you apply.</span> </div><div> You could build your credit rating over time as long as you always make your payments on time and stay within your credit limit. However, if you don't do those things, your credit rating could be harmed. </div><div> Your standard and cash interest rates will move up and down in line with the Bank of England Base Rate. Base Rate changes will not apply to your promotional interest rates. To learn more visit <a>barclaycard.co.uk/understanding-interest</a>. </div></div>",
          "listValues": [],
          "validations": []
        },
        {
          "id": "summaryBox",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Summary box",
          "listValues": [],
          "validations": []
        },
        {
          "id": "problemsUnderstandingInfo2",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "If you would like to talk to someone you can call 0800 056 3486 from 8am - 9pm Monday to Friday and 9am - 1pm on Saturday.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "NoOfferErrorDesc",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Unfortunately,the present circumstances mean the customer is not eligible to apply for Barclaycard.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "PA_title",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "You've been pre-approved for these cards:",
          "listValues": [],
          "validations": []
        },
        {
          "id": "problemsUnderstandingInfo3",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Or you can visit any Barclays branch and speak to an advisor.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "noofferbranch_colleague_bottomText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "<li><strong>EXPLAIN: </strong>Unfortunately, we can't offer you a card right now.<br />This may be because we don't have enough information about your financial history, or because of how you've managed credit in the past.</li><br><b>Direct customer to check credit report and visit barclaycard.co.uk for information about credit reference agencies and scoring.</b>",
          "listValues": [],
          "validations": []
        },
        {
          "id": "contctUsWithQustnDetails",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "If you have any questions about your application just <a href='https://www.barclaycard.co.uk/personal/contact-us' class='target-blank'>contact us</a>",
          "listValues": [],
          "validations": []
        },
        {
          "id": "PurchaseRateText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Purchase Rate",
          "listValues": [],
          "validations": []
        },
        {
          "id": "colleague_title",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Colleague instructions",
          "listValues": [],
          "validations": []
        },
        {
          "id": "NPS_title",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Select the card that suits your needs...",
          "listValues": [],
          "validations": []
        },
        {
          "id": "problemsUnderstandingInfo1",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "We can provide additional support for all customers, including those who have mental or physical health conditions or learning disabilities.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "findOutMoreAndApply",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Find out more and apply",
          "listValues": [],
          "validations": []
        },
        {
          "id": "SummaryBoxLink",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "<a class='overlay'>Read summary box</a>",
          "listValues": [],
          "validations": []
        },
        {
          "id": "btNoteText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "The balance transfer period and interest rates may differ from those shown.",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "basedOnCreditLimitText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Based on a",
          "listValues": [],
          "validations": []
        },
        {
          "id": "gettingInTouch",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Getting in touch",
          "listValues": [],
          "validations": []
        },
        {
          "id": "colleague_intro_prepopulationText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Show the customer cards they're eligible to apply for and explain they can apply for any cards on the list. Ask what the primary use for their card is (e.g., to move debt from other accounts) and show the cards which have that feature, e.g., balance transfers.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "findOutMoreButton",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "find Out More",
          "listValues": [],
          "validations": []
        },
        {
          "id": "colleagueInstructionsBranch",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "<div class='branch-shopping-page-intro'><div class='panel colleague-instructions-branch' tabindex='-1'><div class='panel-body' role='region' tabindex='-1'><b><span class='icon icon-info brand-cyan'></span>Colleague instructions</b><p class='instructions margin-bottom'>Show the customer cards they're eligible to apply for and explain they can apply for any cards on the list. Ask what the primary use for their card is (e.g., to move debt from other accounts) and show the cards which have that feature, e.g., balance transfers.</p><p class='instructions'>Ask the customer the following questions:</p><p class='instructions'>1. Do you have an email address as this will be required in order to process your application in branch today?<span>a. If yes, proceed with the next question.</span><span>b. If no, please let the customer know that their application cannot be processed in Branch. They will need to call the Barclaycard Call Centre on <b>0800 731 0200</b> to complete their application.</span></p><p class='instructions'>2. Do you have an active Barclays current account card with you and do you know the PIN?<span>a. If yes, proceed with the application.</span><span>b. If no, please let the customer know that unfortunately we cannot complete your application in Branch, but you can apply via the Barclays App (we can assist you with that), Barclaycard.co.uk or contact our telephony team on <b>0345 7 345 345.</b></span></p></div></div></div>",
          "listValues": [],
          "validations": []
        },
        {
          "id": "BackButton",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Back",
          "listValues": [],
          "validations": []
        },
        {
          "id": "TNCLink",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "<a>Read balance transfer terms and conditions</a>",
          "listValues": [],
          "validations": []
        },
        {
          "id": "CloudItSuccess",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "To view go to your App, select More and then go to Statement & Documents.",
          "listValues": [],
          "validations": [],
          "placeholder": "Pre-Contract Credit Information"
        },
        {
          "id": "AssumedCreditLimitText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Based on",
          "listValues": [],
          "validations": []
        },
        {
          "id": "mainBulletsTitle",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Your Barclaycard at a glance",
          "listValues": [],
          "validations": []
        },
        {
          "id": "CloudItError",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Please try again later",
          "listValues": [],
          "validations": [],
          "placeholder": "Upload failed"
        },
        {
          "id": "problemsUnderstdngHeading",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Having problems understanding this information?",
          "listValues": [],
          "validations": []
        },
        {
          "id": "NoOfferErrorCloseBtn",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Close",
          "listValues": [],
          "validations": []
        },
        {
          "id": "APRText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Representative APR",
          "listValues": [],
          "validations": []
        },
        {
          "id": "InterestOfferWorkText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "<div class='product-details-content'><h3>How your interest offers work</h3><div><b>Your balance transfer offer</b>-<span> 0% balance transfers can only be made within the first 60 days from account opening.</span></div><div><b>To keep your 0% rates</b>- <span> you'll need to stay within your credit limit and make at least your minimum payment on time each month. If you don't, your 0% rates will go up to your standard rate instead.</span> </div><div> <b>When your 0% purchase offer ends</b>- <span> you'll be charged interest at your standard rate for:</span> </div><ul class='tick-list'> <li>anything new you buy</li><li>any outstanding purchase balance on your account</li></ul> <div>If you don't pay off your whole balance each month, including any balances you've transferred, then you'll be charged interest on your new purchases from the day the transaction appears on your account.</div><div> <b>When your 0% balance transfer offer ends</b>- <span> you'll be charged interest at your standard rate on any outstanding balance transfer.</span> </div></div>",
          "listValues": [],
          "validations": []
        },
        {
          "id": "PS_title",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "You have been pre-selected to apply for...",
          "listValues": [],
          "validations": []
        },
        {
          "id": "ProductFooter",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "We can provide additional support for all customers, including those who have mental or physical health conditions or learning disabilities.",
          "tooltip": "If you would like to talk to someone call <a href='tel:0800 056 3486'>0800 056 3486</a> from 9am - 5pm Monday to Friday and 9am - 4pm on Saturday.",
          "listValues": [],
          "validations": [],
          "placeholder": "Contact us if you’re having difficulty understanding"
        }
      ],
      "menus": []
    },
    {
      "screenid": "BMB_Footer_Shopping_Page",
      "elements": [
        {
          "id": "footerCopyRight",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "&copy; Barclaycard 2016",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "footerPrivacyInfo",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "See more information about <a href='/uka-client-index.html#privacy-policy' class='target-blank'>privacy & cookies</a>",
          "length": 128.0,
          "listValues": [],
          "validations": []
        }
      ],
      "menus": []
    },
    {
      "screenid": "SHOPPING_PAGE_WITH_CATEGORY",
      "elements": [
        {
          "id": "suggested",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "placeholder": "Suggested",
          "label": "Suggested Card",
          "tooltip": "Card we think you’re likely to be accepted for based on the information we have about you",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "topOffers",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "placeholder": "Top offers",
          "label": "Top offers",
          "tooltip": "Our best offers on cards right now",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "purchaseOffer",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "placeholder": "Purchase offer",
          "label": "Purchase offer cards",
          "tooltip": "Our purchase cards let you make purchases without paying any interest on the balance for a certain period",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "creditBuilding",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "placeholder": "Credit building",
          "label": "Credit building cards",
          "tooltip": "If you’re looking to improve your credit score or start building one from scratch, our cards could help",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "rewards",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "placeholder": "Rewards",
          "label": "Rewards cards",
          "tooltip": "Collect travel rewards or cashback on your everyday spend",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "balanceTransfer",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "placeholder": "Balance transfer",
          "label": "Balance transfer cards",
          "tooltip": "If you’re paying interest on credit or store cards, you could consolidate what you owe with one of our balance transfer cards",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "allCards",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "placeholder": "All cards",
          "label": "All cards",
          "tooltip": "Every credit card that we offer",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },

        {
          "id": "colleagueInstructionsBranchText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "<ol><li>Do you have an email address as this will be required in order to process your application in branch today?<ol type='a'><li>If yes, proceed with the next question.</li><li> If no, please let the customer know that their application cannot be processed in Branch. They will need to call the Barclaycard Call Centre on <strong>0800 731 0200</strong> to complete their application.</li></ol></li><li>Do you have an active Barclays current account card with you and do you know the PIN?<ol type='a'><li>If yes, proceed with the application.</li><li>If no, please let the customer know that unfortunately we cannot complete your application in Branch, but you can apply via the Barclays App (we can assist you with that), Barclaycard.co.uk or contact our telephony team on <strong>0345 7 345 345</strong>.</li></ol></li></ol>",
          "listValues": [],
          "validations": []
        },
        {
          "id": "RepresentativeExampleText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Representative Example",
          "tooltip": "Most accepted customers get:",
          "listValues": [],
          "validations": []
        },
        {
          "id": "APRText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Representative",
          "listValues": [],
          "validations": []
        },
        {
          "id": "basedOnCreditLimitText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Based on a",
          "listValues": [],
          "validations": []
        },
        {
          "id": "PurchaseRateText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Purchase Rate",
          "listValues": [],
          "validations": []
        },
        {
          "id": "accountFeeText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Monthly fee",
          "listValues": [],
          "validations": []
        },
        {
          "id": "summaryBox",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Summary box",
          "listValues": [],
          "validations": []
        },
        {
          "id": "CloudItLinkText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Download to view in your app",
          "placeholder": "Pre-Contract Credit Information",
          "listValues": [],
          "validations": []
        },
        {
          "id": "noteText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "The approval of your application depends on financial circumstances and borrowing history, so do the terms you may be offered.",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "productListNoteText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Subject to application, financial circumstances and borrowing history.",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "paNoteText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "The approval of your application depends on your financial circumstances and borrowing history.",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "btNoteText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "The balance transfer period and interest rates may differ from those shown.",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "checkEligibilityButton",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Check eligibility & apply",
          "listValues": [],
          "validations": []
        },
        {
          "id": "confirmEligibilityButton",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Confirm eligibility & apply",
          "listValues": [],
          "validations": []
        },
        {
          "id": "mainBulletsTitle",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Your Barclaycard at a glance",
          "listValues": [],
          "validations": []
        },
        {
          "id": "ProductFooter",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "placeholder" : "Contact us if you’re having difficulty understanding",
          "label": "We can provide additional support for all customers, including those who have mental or physical health conditions or learning disabilities.",
          "tooltip": "If you would like to talk to someone call <a href='tel:0800 056 3486'>0800 056 3486</a> from 9am - 5pm Monday to Friday and 9am - 4pm on Saturday.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "CloudItSuccess",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "placeholder" : "Pre-Contract Credit Information",
          "label": "To view go to your App, select More and then go to Statement & Documents.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "CloudItError",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "placeholder" : "Upload failed",
          "label": "Please try again later.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "PreApprovedText",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "placeholder" : "You are pre-approved",
          "label": "Based on the information we hold on you, subject to final responsible lending checks.",
          "tooltip": "Being pre-approved means the interest rate, any interest-free period and fee (if there is one), are all confirmed. The only thing we can’t guarantee is the credit limit - this will be confirmed after you apply and are accepted.",
          "listValues": [],
          "validations": []
        },
        {
          "id": "ApplyNowButton",
          "visible": false,
          "rendered": true,
          "required": false,
          "readOnly": false,
          "label": "Apply now",
          "listValues": [],
          "validations": []
        }
      ],
      "menus": []
    },
    {
      "screenid": "onlineHeader_Card_Application",
      "elements": [
        {
          "id": "sitemap",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "<a href='https://www.barclaycard.co.uk/personal/sitemap' title='opens in a new window' target='_blank' aria-hidden='false'>Sitemap</a>",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "accessibility",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "<a href='https://www.barclaycard.co.uk/personal/accessibility' title='opens in a new window' target='_blank' aria-hidden='false'>Accessibility</a>",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "menu",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "Menu",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "contactUs",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "<a href='https://www.barclaycard.co.uk/personal/contact-us' title='opens in a new window' target='_blank' aria-hidden='false'>Contact Us</a>",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "privacyAndCookiePolicy",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "<a href='/uka-client-index.html#privacy-policy' title='opens in a new window' target='_blank' aria-hidden='false'>Privacy and Cookie Policy</a>",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "security",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "<a href='https://www.barclaycard.co.uk/personal/security' title='opens in a new window' target='_blank' aria-hidden='false'>Security</a>",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "nearestBranch",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "<a href='http://ask.barclays.co.uk/branchfinder/' title='opens in a new window' target='_blank' aria-hidden='false'>Nearest Branch</a>",
          "length": 128.0,
          "listValues": [],
          "validations": []
        }
      ],
      "menus": []
    },
    {
      "screenid": "BMB_Footer_Shopping_Page",
      "elements": [
        {
          "id": "footerCopyRight",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "&copy; Barclaycard 2016",
          "length": 128.0,
          "listValues": [],
          "validations": []
        },
        {
          "id": "footerPrivacyInfo",
          "visible": false,
          "rendered": true,
          "required": false,
          "componentType": "FIELD",
          "readOnly": false,
          "label": "See more information about <a href='/uka-client-index.html#privacy-policy' class='target-blank'>privacy & cookies</a>",
          "length": 128.0,
          "listValues": [],
          "validations": []
        }
      ],
      "menus": []
    }
  ]
}

export default _meta;