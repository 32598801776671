import React from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { Type, Icon, Separator,LinkButton,ScreenReader } from '@barclays/blueprint-react';
import parse from 'html-react-parser';
import RepresentativeContent from './components/RepresentativeContent';
import { CategoryInterface } from './types/ShoppingPageContextTypes'
import { ElementMapType } from './types/ShoppingPageMetaTypes';
import { getUIContext } from '../../constants';

type Props = {
    categoryData: CategoryInterface,
    meta: ElementMapType
}
export default function ProductByCategory({ categoryData,meta }: Props) {
    const navigate = useNavigate();
    let location = useLocation();
    const showProductDetail = (pc: string) => {
        navigate(location.pathname+ '/' + pc + '' + location.search);
    }
    return (
        <>
            <div className='category-details'>
                <Type element='h1' alignment='center' size='xl' marginBottom='sm' weight='light'>{categoryData.label}</Type>
                <Type element='p' alignment='center' className='txt-sm' marginBottom='sm'>{categoryData.tooltip}</Type>
                <p className='category-info txt-xs'>
                {categoryData.offers.filter((e)=>(!e.spPreApproved)).length===0?
                meta.paNoteText.label:meta.noteText.label}</p>
            </div>
            <div className='offer-list' role="list">
                {categoryData.offers.length === 0 && <p className='empty-offer'>Sorry, we can't offer you a card right now</p>}
                {categoryData.offers.length > 0 && categoryData.offers.map((o,key) => (
                    <div className='offer-item' data-pc={o.productCode} key={key} onClick={() => showProductDetail(o.productCode)} tabIndex={0} role="listitem">
                        <img src={getUIContext(location.pathname) + 'images/' + (o.smallCardImage)} className='o-icon card-icon' alt={o.spProdDesc} aria-hidden={true}/>
                        <img src={getUIContext(location.pathname) + 'images/' + (o.cardImage)} className='o-icon card-img' alt={o.spProdDesc} aria-hidden={true}/>
                        <Type element='h2' size='lg' color='info' marginBottom='xs'>{o.spProdDesc}</Type>
                        <div className='o-badges'>
                            {o.spPreApproved && <><img src={getUIContext(location.pathname) + 'images/preapproved-badge.png'} alt='pre approved' aria-hidden={true}/><ScreenReader> Pre-approved badge &nbsp;</ScreenReader></>}
                            {o.topOffer && <><img src={getUIContext(location.pathname) + 'images/topoffer-badge.png'} alt='top offer' aria-hidden={true}/><ScreenReader> Top offer badge</ScreenReader></>}
                        </div>
                        <Type element='p' className='txt-sm'>{parse(o.spPromoText)}</Type>
                        <Separator spacing='xs' />
                        <div className='representative'>
                        {o.amfValue===0 && <RepresentativeContent offerData={o} meta={meta} collapsed className='txt-sm'/> }
                        {o.amfValue!==0 && <>
                            <Type element='h3' weight='bold' className='txt-sm' marginBottom='xs'>{meta.RepresentativeExampleText.label}</Type>
                            <RepresentativeContent offerData={o} meta={meta} className='txt-sm'/>
                        </>}
                        </div>
                        <Separator spacing='xs' />
                        <div className='footer-notes'>
                            <Type element='p' size='xs' className='footer-content txt-xs'>
                                {meta.productListNoteText.label}
                                {o.spInstantBTFlag && !o.spPreApproved && " " + meta.btNoteText.label}
                            </Type>
                        </div>
                        <span className='arrow-right'>
                        <LinkButton mediaOnly="always" media={<Icon icon="chevron-right" size="lg" />}>View {o.spProdDesc} Card Details</LinkButton>
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
}