import { Type, Icon } from '@barclays/blueprint-react';
import { ElementMapType } from '../types/ShoppingPageMetaTypes';
import parse from 'html-react-parser';
import './footer.css'

type Props = {
    meta: ElementMapType
}

export default function Footer({meta}:Props) {
    return (<div className='footer'>
        <div className='info-icon'><Icon icon='info' size='sm' status='info' /></div>
        <Type element='p' size='md' marginBottom='xs'>{meta.ProductFooter.placeholder}</Type>
        <Type element='p' className='txt-sm' marginBottom='xs'>{meta.ProductFooter.label}</Type>
        <Type element='p' className='txt-sm' marginBottom='sm'>{parse(meta.ProductFooter.tooltip!)}</Type>
    </div>);
}