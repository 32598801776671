import { useRef } from 'react';
import { TaggingType } from '../types/ShoppingPageTaggingTypes'

// function validateResponse(response:TaggingType){
//   // validate Tagging Data
//   return response;
// }

declare const window: any;

function useTagging() {
  const taggingDataRef = useRef({
    'page_name' : '', 
    'appname' : 'ShoppingPage',
    'page_version' : '',
    'page_level1' : 'bcard',
    'page_level2' : 'apply',
    'page_level3' : '',
    'page_level4' : '',
    'page_platform' : 'OLAF',
    'page_impressions' : '',
    'event_product' : '',
    'event_error_code' : '',
    'event_applicationid' : '',
    'event_applicationtype' : '',
    'event_error_form_fields' : '',
    'event_label' : '',
    'event_destination' : '',
    'event_type' : 'screenload',
    'event_method' : '',
    'event_value' : '',
    'event_section' : '',
    'event_caseid' : '',
    'page_channel_id' : '1',
    'event_applicationid_obi' : '', // need to save in local storage
    'page_url_hash' : '',
    'page_url_querystring' : '',
    'event_campaign' : '',
    'page_referring_url' : document.referrer,
    'page_url_path' : '',
    'page_title' : '',
    'event_product_offered' : '',
    'event_product_accepted' : '',
    'event_application_primaryuse' : '',
    'event_application_btappliedct' : '',
    'event_application_btappliedval' : '',
    'event_applicationresult' : '',
    'bcard_application_btacceptedct' : '',
    'bcard_application_btacceptedval' : '',
    'user_registered' : '',
    'event_application_decision_iv' : '',
    'event_application_bt_cancel' : '',
    'user_eligible_products' : ''
  } as TaggingType);

  const updateTaggingData = (updatedTags: TaggingType) => {
    if(window.localStorage && window.localStorage.getItem('bmbTaggingSwitch')==='ON'){
      // update tags
      let updatedTaggingData:TaggingType = {
        ...taggingDataRef.current,
        ...updatedTags
      };
      updatedTaggingData.page_name = updatedTaggingData.page_level1+ ":" +  updatedTaggingData.page_level2+ ":" + updatedTaggingData.page_level3
      taggingDataRef.current = updatedTaggingData;
      let taggingEvent = updatedTaggingData.event_type==='screenload'?'A2WTealiumPageLoad':'A2WTealiumUserAction';
      if(!updatedTaggingData.event_applicationid_obi){
        updatedTaggingData.event_applicationid_obi = (window.localStorage && window.localStorage.getItem('event_applicationid_obi')) || "";
      }
      updateTaggingOnLoadBMB(updatedTaggingData,taggingEvent);
      window.localStorage && window.localStorage.setItem("digitalData",JSON.stringify(updatedTaggingData));
   }
  }
  const updateTaggingOnLoadBMB = (updatedTaggingData: TaggingType,taggingEvent:String) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (window.localStorage && window.localStorage.getItem('bmbTaggingSwitch')==="ON") {
      if (
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ) {
        window?.webkit?.messageHandlers?.BMBiOSHandler?.postMessage({
          name: taggingEvent,
          taggingPayload: updatedTaggingData,
        });
      } else if (/android/i.test(userAgent)) {
        window?.mca2JavaScriptInterface?.onEventFired(
          taggingEvent,
          JSON.stringify({ taggingPayload: updatedTaggingData })
        );
      }
    }
  }

  return {
    updateTaggingData
  }
}

export default useTagging;