import React,{useEffect} from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter,useLocation, Routes, Route, Outlet } from 'react-router-dom';

import { BarclaysLegacyThemeProvider } from '@barclays/blueprint-react';

import ShoppingPage from './routes/shopping-page';
import ProductList from './routes/shopping-page/ProductList';
import ProductDetails from './routes/shopping-page/ProductDetails';
import SummaryBox from './routes/shopping-page/SummaryBox';
import App from './App';
import { UIContext } from './constants';
import ErrorPage from './ErrorPage';
import ApplicationBootstrap from './ApplicationBootstrap'
import { SPProvider } from './routes/shopping-page/context/SPContext';
import './index.css';

type Props = {
  children : React.ReactNode
}
const SPProviderWrapper = () => (
  <SPProvider>
    <Outlet />
  </SPProvider>
);

const ScrollToTop = ({children}:Props) => {
const location = useLocation();
useEffect(() => {
  window.scrollTo(0, 0);
}, [location]);

return <>{children}</>
};

const root = createRoot(document.getElementById('root')!);
root.render(
    <BarclaysLegacyThemeProvider>
    <BrowserRouter>
    <ScrollToTop>
    <Routes>
      <Route path='*' element={<ErrorPage header={true}/>} />
      <Route path='/' element={<App />} >
        <Route index element={<ErrorPage header={true}/>} />
        <Route element={<SPProviderWrapper />}>
          <Route path='bmb/shopping/*' element={<ApplicationBootstrap />} />
          <Route path='shopping-page' element={<ShoppingPage />}>
              <Route index element={<ProductList />} />
              <Route path=':productCode'>
                  <Route index element={<ProductDetails />} />
                  <Route path=':summaryBox' element={<SummaryBox />} /> 
              </Route>
          </Route>
        </Route>
      </Route>
      <Route path={UIContext+'/'} element={<App />} >
        <Route index element={<ErrorPage header={true}/>} />
        <Route element={<SPProviderWrapper />}>
        <Route path='bmb/shopping/*' element={<ApplicationBootstrap />} />
        <Route path='shopping-page' element={<ShoppingPage />}>
            <Route index element={<ProductList />} />
            <Route path=':productCode'>
                <Route index element={<ProductDetails />} />
                <Route path=':summaryBox' element={<SummaryBox />} /> 
            </Route>
        </Route>
      </Route>
      </Route>
    </Routes>
    </ScrollToTop>
  </BrowserRouter>
  </BarclaysLegacyThemeProvider>
);
