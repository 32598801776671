import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Type, Icon, Button, Separator, Dialog, ModalRef, Link, FormMultiItem, FormRadio,ScreenReader } from '@barclays/blueprint-react';
import { SPContext } from '../context/SPContext';
import { ElementInterface } from '../types/ShoppingPageMetaTypes'
import './categoryselection.css'

const showPageHeader = () => {
    let channelId = window.localStorage && window.localStorage.getItem('channelId');
    let bmbHeaderSwtich = window.localStorage && window.localStorage.getItem('bmbHeaderSwitch');
    return (channelId==="1" && bmbHeaderSwtich==="ON")?false:true;
  }

export default function CategorySelection() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedRadio,setSelectedRadio] = useState('');
    const modalRef = useRef<ModalRef>({} as ModalRef);
    const selectedRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
    const { getCategories } = useContext(SPContext);
    useEffect(() => {
        if(searchParams.get('showFilter')==="true"){
            setSelectedRadio(searchParams.get('category') || "");
            modalRef.current.open();
        }
        else{
            modalRef.current.close();
        }
    },[searchParams]);

    useEffect(() => {
        selectedRef.current && selectedRef.current.scrollIntoView && selectedRef.current.scrollIntoView({ inline: 'center' });
    });

    const updateCategory = useCallback((category: string) => {
        setSearchParams((params)=> ({ ...params, ...{ 'category': category } }),{replace: true});
    },[setSearchParams]);

    const doOpenModal = useCallback(() => {
        setSelectedRadio(searchParams.get('category') || "");
        searchParams.set("showFilter", 'true');
        setSearchParams(searchParams);
    }, [searchParams,setSearchParams]);

    const doCloseModal = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        updateCategory(selectedRadio);
        modalRef.current.close();
    }, [selectedRadio,updateCategory]);
    
    return (
        <div className='category'>
            <div id='categoryButtons' className='category-button'>
                <span className='settings-btn'>
                    {showPageHeader() && <Button mediaOnly autoFocus small variant='primary' onClick={doOpenModal} media={<Icon icon='chevron-down' size='sm' />}>
                    <ScreenReader>You are currently on Product Listing Page. Click to choose &nbsp;</ScreenReader>
                    Category Options</Button>}
                    {!showPageHeader() && <Button mediaOnly small variant='primary' onClick={doOpenModal} media={<Icon icon='chevron-down' size='sm' />}>
                    <ScreenReader>Click to choose &nbsp;</ScreenReader>
                    Category Options</Button>}
                </span>
                {getCategories().map((item: ElementInterface, key: number) => (
                    <span key={key}>
                        {searchParams.get('category') === item.id &&
                            <Button small mediaPositionRight id={item.id} ref={selectedRef} variant='primary'
                                media={<Icon icon='tick' size='xs' />}>{item.placeholder}<ScreenReader>Category Button Selected</ScreenReader></Button>}
                        {searchParams.get('category') !== item.id &&
                            <Button small id={item.id} onClick={() => updateCategory(item.id)}>{item.placeholder}<ScreenReader>Category Button</ScreenReader></Button>}
                    </span>
                ))}
            </div>
            <span className='partition'></span>
            <div className='expand'>
                <Button mediaOnly small variant='primary' onClick={doOpenModal} media={<Icon icon='chevron-down' size='sm' />}>Category Options</Button>
                <Dialog
                    ref={modalRef}
                    alignment='bottom'
                    size='content'
                    transparency='dark'
                    title={<>
                        <Link href='' className='modal-done' onClick={doCloseModal}>Done</Link>
                        <Type element='h2' size='lg' alignment='center'>View</Type>
                        <Separator spacing='sm' />
                    </>}
                >
                    <div className='modal-category-select'>
                        <FormMultiItem
                            role='radiogroup'
                            label=''
                            render={(renderProps) => (
                                <>
                                    {getCategories().map((item: ElementInterface, key: number) => (
                                        <span key={key}>
                                            {selectedRadio === item.id &&
                                                <FormRadio name='radio' key={key} defaultChecked value={item.id} label={item.placeholder!} {...renderProps} />}
                                            {selectedRadio !== item.id &&
                                                <FormRadio name='radio' key={key} value={item.id} onChange={() => setSelectedRadio(item.id)} label={item.placeholder!} {...renderProps} />}
                                        </span>
                                    ))}
                                </>
                            )}
                        />
                    </div>
                </Dialog>
            </div>
        </div>

    );
}
