import React, { useContext } from 'react';
import { Type, Section, SectionItem,HeroAlert,Icon,Alert } from '@barclays/blueprint-react';
import { SPContext } from './context/SPContext';
import parse from 'html-react-parser';

export default function NoOfferFound() {
  const { getMetaElements,data } = useContext(SPContext);
  return (
    <div className="no-offer-found">
      <Section>
        <SectionItem fullWidth={false}>
          <Type element='h1' size='xl' marginBottom={true}>Your Decision</Type>
          {(data.channelId===4 || data.channelId===5) && <div className='branch-instructions'><Alert
                head={<Type size="md" weight="medium" className='instructions-title'>{getMetaElements('SHOPPING_PAGE').noofferbranch_colleague_title.label}</Type>}
                icon="info"
                variant="info"
                >
                <Type>{getMetaElements('SHOPPING_PAGE').noofferbranch_colleague_introText.label}</Type>
            </Alert>
            </div>}
          <div className='hero-alert-wrapper'>
          <HeroAlert
            announceOnMount
            media={<Icon icon="info" size="xl" status="info"/>}
          >
            <React.Fragment key=".0">
              <Type
                element="h1"
                size="xl"
              >
                {getMetaElements('SHOPPING_PAGE').noofferbranch_text.label}
              </Type>
            </React.Fragment>
          </HeroAlert>
          </div>
          {(data.channelId===4 || data.channelId===5) && <div className='branch-instructions bottom'><Alert
                head={<Type size="md" weight="medium" className='instructions-title'>{getMetaElements('SHOPPING_PAGE').noofferbranch_colleague_title.label}</Type>}
                icon="info"
                variant="info"
                >
                <Type>{parse(getMetaElements('SHOPPING_PAGE').noofferbranch_colleague_bottomText.label)}</Type>
            </Alert>
          </div>}
        </SectionItem>
      </Section>
    </div>
  );
}