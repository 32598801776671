import React, { useContext,useEffect } from 'react';
import { Outlet } from 'react-router-dom'
import { Loading } from '@barclays/blueprint-react';
import { SPContext } from './context/SPContext'; 
import { useSearchParams } from 'react-router-dom';
import ErrorPage from '../../ErrorPage';
import NoOfferFound from './NoOfferFound';
import PageHeader from './PageHeader';
import './index.css';

export default function ShoppingPage() {
    return (
        <ShoppingPageBootstrap />
    );
  }

  function ShoppingPageBootstrap(){
    const [searchParams] = useSearchParams();
    const {metaRequestStatus, dataRequestStatus,getCategoryData,data,fetchMetaApi,fetchDataApi } = useContext(SPContext);
    useEffect(()=>{
      if(metaRequestStatus==="idle"){
        fetchMetaApi();
      }
    },[fetchMetaApi,metaRequestStatus]);

    useEffect(()=>{
      if(dataRequestStatus==="idle"){
        fetchDataApi();
      }
    },[fetchDataApi,dataRequestStatus]);
    const showHeader = () => {
      let channelId = window.localStorage && window.localStorage.getItem('channelId');
      let bmbHeaderSwtich = window.localStorage && window.localStorage.getItem('bmbHeaderSwitch');
      return (channelId==="1" && bmbHeaderSwtich==="ON")?true:false;
    }
    const isValidCategory = () => {
      if(!searchParams.get('category') || searchParams.get('category')==="allCards"){
        return true;
      }
      return (searchParams.get('category') && getCategoryData(searchParams.get('category')).id) || false;
    }
    return (<>
    {showHeader() && <PageHeader />}
    <div className={showHeader()?"sp-container padding-top":"sp-container"}>
    {(metaRequestStatus==="loading" || dataRequestStatus==="loading") && <div className="loading"><Loading shape="circle" /></div>}
    {(metaRequestStatus==="failure" || dataRequestStatus==="failure") && <ErrorPage />}
    {metaRequestStatus==="success" && dataRequestStatus==="success" && 
    !isValidCategory() && <ErrorPage />}
    {metaRequestStatus==="success" && dataRequestStatus==="success" && 
    isValidCategory() && (!data.offers || data.offers.length===0) && <NoOfferFound />}
    {metaRequestStatus==="success" && dataRequestStatus==="success" && 
    isValidCategory() && data.offers && data.offers.length>0 && <Outlet />}
    </div>
    </>
    );
  }