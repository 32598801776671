import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Type,Alert } from '@barclays/blueprint-react';
import ProductByCategory from './ProductByCategory'
import CategorySelection from './components/CategorySelection';
import { SPContext } from './context/SPContext';
import {OfferType} from './types/ShoppingPageDataTypes';
import parse from 'html-react-parser';


export default function ProductList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { getMetaElements, getCategoryData,getCategories,data,updateTaggingData } = useContext(SPContext);
    useEffect(() => {
        if (!searchParams.get('category')) {
            setSearchParams((params)=>({ ...params, ...{ 'category': getCategories()[0].id } }),{ replace: true });
        }
    },[searchParams, setSearchParams, getCategories]);

    useEffect(() => {
      if (searchParams.get('category')) {
        try{
          updateTaggingData({
            'page_level3': 'shopping:'+ searchParams.get('category'),
            'page_impressions':data.bsp+"|"+data.psc+"|"+data.journeyType,
            'user_eligible_products': getCategoryData().offers.map((o:OfferType)=>o.productCode).join("|"),
            'event_product': getCategoryData(searchParams.get('category')).offers.map((o:OfferType)=>o.productCode).join("|"),
            'event_type': 'screenload'
          });
        }
        catch(e){
          console.warn("Tagging called failed");
        }
      }
    },[searchParams,data,updateTaggingData,getCategoryData]);
    return (<div id='shopping-page' className='shopping-page-redesign'>
        <div className='category-header'>
            <CategorySelection />
        </div>
        <div id='offerListContainer' className='offer-list-wrapper'>
            {data.channelId===4 && <div className='branch-instructions'><Alert
                head={<Type size="md" weight="medium" className='instructions-title'>Colleague instructions</Type>}
                icon="info"
                variant="info"
                >
                <Type>{getMetaElements('SHOPPING_PAGE').colleague_intro_prepopulationText.label}</Type>
                <Type>{parse(getMetaElements('SHOPPING_PAGE_WITH_CATEGORY').colleagueInstructionsBranchText.label)}</Type>
            </Alert>
            </div>}
            {data.channelId===5 && <div className='branch-instructions'><Alert
                head={<Type size="md" weight="medium" className='instructions-title'>Colleague instructions</Type>}
                icon="info"
                variant="info"
                >
                <Type>{getMetaElements('SHOPPING_PAGE').colleague_intro_prepopulationText.label}</Type>
            </Alert>
            </div>}
            {searchParams.get('category') && getMetaElements('SHOPPING_PAGE_WITH_CATEGORY')[searchParams.get('category') || 'allCards']
                && <ProductByCategory categoryData={getCategoryData(searchParams.get('category'))} meta={getMetaElements('SHOPPING_PAGE_WITH_CATEGORY')} />}
        </div>
    </div>);
}