import React, { useState,useEffect,useRef,useContext } from 'react';
import { Content,Type,Button,Loading,ScreenReader } from '@barclays/blueprint-react';
import { useParams,useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import axios from './hooks/axiosAdaptor';
import { SPContext } from './context/SPContext';
// import _summarybox from './hooks/summaryBox';
import { SUMMARY_URL } from '../../constants';
import './summaryBox.css';


const parser = (input: string) =>
  parse(input, {
    replace: domNode => {
      const domElement: Element = domNode as Element;
      if (domElement.attribs && domElement.attribs.role === 'button') {
        return (<div className="summary-back-btn">
            <Button variant='primary' fullWidth onClick={() => showProductDetail()}>Back</Button>
          </div>);
      }
      return;
    }
  });

const showProductDetail = () => {
  window.history.back();
}
const showPageHeader = () => {
  let channelId = window.localStorage && window.localStorage.getItem('channelId');
  let bmbHeaderSwtich = window.localStorage && localStorage.getItem('bmbHeaderSwitch');
  return (channelId==="1" && bmbHeaderSwtich==="ON")?false:true;
}
export default function SummaryBox() {
  const autoFocusRef = useRef<HTMLDivElement>(null);
  let { productCode } = useParams();
  const [searchParams] = useSearchParams();
  const [loading,setLoading] = useState<boolean>(false);
  const [summaryHtml,setSummaryHtml] = useState<string>('');
  const {updateTaggingData } = useContext(SPContext);
  useEffect(() => {
    if (autoFocusRef.current !== null) {
      autoFocusRef.current.focus();
    }
  }, []);
  useEffect(()=>{
    const invokeSummaryBox = () => {
      setLoading(true);
      axios.post(SUMMARY_URL,{ 
        productCode: productCode,
        type : "html" 
      })
      .then(function (response) {
        setSummaryHtml(response.data.htmlContent || '');
        setLoading(false);
        try{
          updateTaggingData({
            'page_level3': 'shopping:'+ searchParams.get('category')+ ":" + productCode + ":summarybox",
            'event_type': 'screenload'
          });
        }
        catch(e){
          console.warn("Tagging called failed");
        }
      })
      .catch(function () {
        setLoading(false);
      });
    }
    invokeSummaryBox();
  },[productCode,updateTaggingData,searchParams]);
    return (
      <div id='summaryBoxWrapper'>
        {showPageHeader() && <div className='header'>
          <div ref={autoFocusRef} tabIndex={-1}>
            <ScreenReader>You are currently on SummayBox Page.</ScreenReader>
          </div>
          <Type element='h1' alignment='center' size='md' className='txt'>Rates/charges</Type>
        </div>}
      {loading && <div className="loading"><Loading shape="circle" /></div>}
      <Content>
      {!loading && parser(summaryHtml)}
      </Content>
      </div>
    );
  }