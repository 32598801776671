import React,{useState,useRef,useContext} from 'react';
import { useParams, useNavigate, useLocation,useSearchParams } from 'react-router-dom';
import { Type,LinkButton,Icon,ScreenReader } from '@barclays/blueprint-react';
import axios from './hooks/axiosAdaptor';
import { SPContext } from './context/SPContext';
import _ from 'lodash';
import { CLEAR_URL,getUIContext } from '../../constants';

type Props = {
  pageError?: Boolean
}
export default function PageHeader({ pageError }: Props) {
  const autoFocusRef = useRef<HTMLDivElement>(null);
  const [closeApp,setCloseApp] = useState(false);
  let { productCode, summaryBox } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let location = useLocation();
  const {updateTaggingData,getCategoryData,metaRequestStatus,dataRequestStatus,data } = useContext(SPContext);


  const isValidCategory = () => {
    if(!searchParams.get('category') || searchParams.get('category')==="allCards"){
      return true;
    }
    return (searchParams.get('category') && getCategoryData(searchParams.get('category')).id) || false;
  }
  const isPageError = () => {
    let apiFailure = (metaRequestStatus==="failure" || dataRequestStatus==="failure");
    let inValidCategory = metaRequestStatus==="success" && dataRequestStatus==="success" && !isValidCategory();
    let noOfferFound = metaRequestStatus==="success" && dataRequestStatus==="success" && 
    isValidCategory() && (!data.offers || data.offers.length===0);
    return pageError || (apiFailure || inValidCategory || noOfferFound)?true:false;
  }

  const getTitle = () => {
    const pathDepth = location.pathname.split("/") && location.pathname.split("/").length;
    const shoppingPage = location.pathname.split("/")[1]==="shopping-page"?true:false
    if (autoFocusRef.current !== null) {
      autoFocusRef?.current?.focus();
    }
    if(shoppingPage && pathDepth===4){
      return "Rates/charges";
    }
    else if(shoppingPage && pathDepth===3){
      return _.capitalize(_.startCase(searchParams.get('category') || ''));
    }
    else{
      return "Credit cards";
    }
  }
  const goBackToBMB = (fireTags?:boolean) => {
    if(fireTags){
      updateTaggingData({
        'event_label': 'HeaderClose',
        'event_method': 'link',
        'event_type': 'click',
      });
    }
    setCloseApp(true);
    axios.get(CLEAR_URL);
  }
  const goBack = () => {
    updateTaggingData({
      'event_label': 'HeaderBack',
      'event_method': 'link',
      'event_type': 'click',
    });
    if(summaryBox && productCode){
      navigate(`${getUIContext(location.pathname)}shopping-page/${productCode}` + location.search);
    }
    else if(productCode) {
      navigate(`${getUIContext(location.pathname)}shopping-page` +location.search);
    }
    else{
      goBackToBMB();
    }
  }
  return (
    <div className="page-header">
        <div ref={autoFocusRef} tabIndex={-1}>
          <ScreenReader>{getTitle()}</ScreenReader>
        </div>
        {!isPageError() && <span className='back-btn'>
            <LinkButton mediaOnly="always" media={<Icon icon="back" size="sm" />} onClick={() => goBack()}>
            Back</LinkButton>
        </span>}
        <Type element='h1' size='md' alignment="center">{getTitle()}</Type>
        {isPageError() && <span className='close-btn'>
            <LinkButton onClick={() => goBackToBMB(true)}>Close</LinkButton>
        </span>}
        {closeApp && <>
          <iframe id="return-to-BMB-Iframe" title="Olaf BMB Iframe" src="barclayload://done" style={{'display':'none'}}></iframe>
        </>}
    </div>
  );
}