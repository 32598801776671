import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Type, Loading } from '@barclays/blueprint-react';
import axios from './routes/shopping-page/hooks/axiosAdaptor';
import { SPContext } from './routes/shopping-page/context/SPContext';
import ErrorPage from './ErrorPage';
import { CLEAR_URL,INIT_URL,PREPROCESS_URL,getChannelName,getJourneyType } from './constants';
import './index.css'

export default function ApplicationBootstrap() {
  const [requestStatus, setRequestStatus] = useState('loading');
  const [showHeader,setShowHeader] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let location = useLocation();
  const {fetchMetaApi:fetchSPMetaAPI,metaRequestStatus:spMetaRequestStatus} = useContext(SPContext);
  useEffect(()=>{
    const journeyType = getJourneyType(location.pathname);
    const spJourney = (journeyType==="shopping") || false;
    if(spJourney && spMetaRequestStatus==="idle"){
      fetchSPMetaAPI && fetchSPMetaAPI();
    }
  },[location,fetchSPMetaAPI,spMetaRequestStatus]);
  useEffect(() => {
    async function fetchData() {
      const channelName = getChannelName(location.pathname);
      const journeyType = getJourneyType(location.pathname);
      try {
        await axios.get(CLEAR_URL);
        const initRes = await axios.get(INIT_URL);
        if (channelName === 'bmb' && window.localStorage) {
          window.localStorage.setItem('event_applicationid_obi', initRes.data.applicationId);
          if (initRes.data.bmbHeaderSwitch === "ON") {
            setShowHeader(true);
            window.localStorage.setItem('bmbHeaderSwitch', initRes.data.bmbHeaderSwitch);
            window.localStorage.setItem('channelId', '1');
          }
          if (initRes.data.bmbTaggingSwitch === "ON") {
            window.localStorage.setItem('bmbTaggingSwitch', 'ON');
          }
        }
        const preprocessRes = await axios({
          method: 'post',
          url: PREPROCESS_URL,
          data: {
            channelName: channelName,
            formType: journeyType,
            parameters: {
              "referenceId": searchParams.get('referenceId')
            }
          }
        });
        // navigate("/shopping-page");
        navigate(preprocessRes.data.nextLink);
        setRequestStatus('success');
      } catch (e) {
        setRequestStatus('failure');
      }
    }
    fetchData();
  }, [searchParams, location, navigate]);
  return (<>
    {showHeader && <div className="page-header">
      <Type element='h1' size='md' alignment="center">Credit cards</Type>
    </div>}
    {requestStatus === "loading" && <div className="loading"><Loading shape="circle" /></div>}
    {requestStatus === "failure" && <ErrorPage header={true} />}
  </>);
}
