import { useState } from 'react';
import axios from './axiosAdaptor';
import { useSearchParams} from 'react-router-dom';
import { DATA_URL } from '../../../constants'
import {RequestType,ShoppingPageDataType,OfferType} from '../types/ShoppingPageDataTypes'
import _ from 'lodash';

function validateResponse(response:ShoppingPageDataType){
  let validOffers = response && response.offers && response.offers.filter((o)=>(
    o.productCategory && 
    typeof o.spPreApproved === 'boolean' && 
    typeof o.amfValue === 'number' && 
    typeof o.topOffer=== 'boolean' ));

  return validOffers && response.offers && validOffers.length ===response.offers.length;
}

function useData(){
    let [data, setData] = useState({} as ShoppingPageDataType);
    const [requestStatus, setRequestStatus] = useState<RequestType>('idle');
    const [searchParams] = useSearchParams();
    const referenceId = searchParams.get('referenceId');

    // useEffect(() => {
      async function fetchDataApi() {
        try {
          setRequestStatus('loading');
          const result = await axios({
            method: 'post',
            url: DATA_URL,
            data: {
              'referenceId': referenceId
            }
          });

          if(validateResponse(result.data)){
            setData(result.data);
            setRequestStatus('success');
          }
          else{
            if(result.data && (result.data.channelId===4 || result.data.channelId===5) && (!result.data.offers || result.data.offers.length===0)){
              console.error("Offer Not Found");
              setData(result.data);
              setRequestStatus('success');
            }
            else{
              console.error("Invalid Response for ",DATA_URL);
              throw new Error('Invalid Response');
            }
          }
        } catch (e) {
          setRequestStatus('failure');
        }
      }
    //   fetchData();
    // }, [referenceId]);
  
    const getOffersDataByCategory = (category:string):OfferType[] => {
      let offers = [] as OfferType[];
      if(category==='topOffers' && data && data.offers ){
        offers = data.offers.filter(function(offer){
          return offer.topOffer;
        });
      }
      else{
        if(data && data.offers){
          offers = data.offers.filter(function(offer){
            return category==='allCards'?true:offer.productCategory.indexOf(_.startCase(category)) !== -1;
          });
        }
      }
      return offers;
  }

    return {
        getOffersDataByCategory,
        data,
        fetchDataApi,
        dataRequestStatus: requestStatus
    }
} 

export default useData;