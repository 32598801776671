import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import _metaRespose from './meta';
import _dataResponse from './data';
import _summarybox from './summaryBox';
import { BASE_URL } from '../../../constants';

const axiosMockInstance = axios.create();
const axiosLiveInstance = axios.create();
export const axiosMockAdapterInstance= new AxiosMockAdapter(axiosMockInstance, { delayResponse: 2000 });
console.log("process.env.isAxioMockk",process.env.REACT_APP_IS_MOCK==="true");
export default (process.env.REACT_APP_IS_MOCK==="true") ? axiosMockInstance : axiosLiveInstance;

axiosMockAdapterInstance.onGet(`${BASE_URL}/channel/meta/shopping-page`).reply(200, _metaRespose);
axiosMockAdapterInstance.onGet(`${BASE_URL}/clear`).reply(200, {});
axiosMockAdapterInstance.onPost(`${BASE_URL}/shopping-page/shoppingPage`).reply(200, _dataResponse);
axiosMockAdapterInstance.onPost(`${BASE_URL}/citupload`).reply(200, {"status":"FAILURE"});
axiosMockAdapterInstance.onGet(`${BASE_URL}/clear`).reply(200,{
    "response": 200,
    "errors": [],
    "screens": []
});
axiosMockAdapterInstance.onPost(`${BASE_URL}/summaryBox`).reply(200, _summarybox);

axiosMockAdapterInstance.onGet(`${BASE_URL}/init`).reply(200,{
    "applicationId": "0524257315481",
    "taggingSwitch": "ON",
    "bmbHeaderSwitch": "ON",
    "bmbTaggingSwitch": "ON"
});
axiosMockAdapterInstance.onPost(`${BASE_URL}/channel/preProcess`).reply(200,{
    "responseCode": "SUCCESS",
    "channelId": 1,
    "journeyType": "ONLINE",
    "formType": "apply",
    "nextLink": "/olafui/shopping-page"
});

axiosMockAdapterInstance.onPost(`${BASE_URL}/summaryBox`).reply(200, _summarybox);
