import React from 'react';
import { Type } from '@barclays/blueprint-react';
import { OfferType } from '../types/ShoppingPageDataTypes';
import { ElementMapType } from '../types/ShoppingPageMetaTypes';
import './representative.css'

type Props = {
    offerData: OfferType,
    collapsed?: boolean | undefined
    size?: 'xs' | 'md' | 'lg' | 'xl' | 'sm' | '2xl' | '3xl' | undefined
    className ?: string | undefined
    marginBottom?: boolean | 'xs' | 'sm' | '2xs' | undefined,
    meta: ElementMapType
}
export default function RepresentativeContent({ offerData,collapsed,size,marginBottom='xs',meta,className }: Props) {
    return (
        <div className='representative'>
            {collapsed &&
                <Type element='span'>
                    <Type element='span' className={className} size={size}>{meta.APRText.label}</Type>
                    <Type element='strong' className={className} size={size} weight='bold'>{parseFloat(offerData.apr).toFixed(1)}% APR</Type>
                    <Type element='span' className={className} size={size}> variable</Type>
                </Type>}
            {!collapsed && <>
                <Type element='p' marginBottom={marginBottom}>
                    <Type element='span' className={className} size={size}>{meta.APRText.label}</Type>
                    <Type element='strong' className={className} size={size} weight='bold'>{parseFloat(offerData.apr).toFixed(1)}% APR</Type>
                    <Type element='span' className={className} size={size}> variable</Type>
                </Type>
                <Type element='p' marginBottom={marginBottom}>
                    <Type element='span' className={className} size={size}>{meta.PurchaseRateText.label}</Type>
                    <Type element='strong' className={className} size={size} weight='bold'>{parseFloat(offerData.airP).toFixed(1)}% p.a.</Type>
                    <Type element='span' className={className} size={size}> variable</Type>
                </Type>
                <Type element='p' marginBottom={marginBottom}>
                    <Type element='span' className={className} size={size}>{meta.basedOnCreditLimitText.label}</Type>
                    <Type element='strong' className={className} size={size} weight='bold'>£{parseInt(offerData.tcbiBal).toLocaleString("en-GB")}</Type>
                    <Type element='span' className={className} size={size}> credit limit</Type>
                </Type>
                <Type element='p' marginBottom={marginBottom}>
                    <Type element='span' className={className} size={size}>{meta.accountFeeText.label}</Type>
                    <Type element='strong' className={className} size={size} weight='bold'>£{offerData.amfValue}</Type>
                </Type>
            </>}
        </div>
    );
}