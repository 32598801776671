import React from 'react';
import { Type, ButtonLink, Section, SectionItem, Panel, Grid } from '@barclays/blueprint-react';
import PageHeader from './routes/shopping-page/PageHeader';

type Props = {
  header?: Boolean
}
const showHeader = () => {
  let channelId = window.localStorage && window.localStorage.getItem('channelId');
  let bmbHeaderSwtich = window.localStorage && window.localStorage.getItem('bmbHeaderSwitch');
  return (channelId==="1" && bmbHeaderSwtich==="ON")?true:false;
}
export default function ErrorPage({header}:Props) {
  return (<>
    {showHeader() && header && <PageHeader pageError={true} />}
    <div className={showHeader() && header ? "error-page padding-top":"error-page"}>
      <Section variant='secondary'>
        <SectionItem fullWidth={false}>
          <Type element='h1' color='info' size='xl' marginBottom={true}>Sorry, something's gone wrong</Type>
          <Panel borders={false}>
            <Panel.Pane>
              <Grid>
                <Grid.Col fill>
                  <Type element='p' marginBottom='sm'>We’re trying to fix the issue as quickly as possible, and your credit rating hasn’t been affected.</Type>
                  <Type element='p' marginBottom='sm'>Please click on the button below to try again.</Type>
                </Grid.Col>
              </Grid>
            </Panel.Pane>
          </Panel>
        </SectionItem>
      </Section>
      <Section variant='secondary' paddingTop={false}>
        <SectionItem fullWidth={false}>
          <ButtonLink variant='primary' role='button' href='https://www.barclaycard.co.uk/personal/credit-cards?ref=home-mainnav'>Start again</ButtonLink>
        </SectionItem>
      </Section>
    </div>
  </>);
}